<template>
  <div class="inner" :style="{ width: coupon.length ? '816px' : '1200px' }">
    <div class="tile_wrap">
      <div class="left">
        <div class="img">
          <img src="@/assets/img/index/miao.png" alt="" />
        </div>
        <span>{{ seckillData.title }}</span>
        <div class="time-down">{{ djs }}</div>
      </div>
      <div class="right c_p" @click="handleMore(seckill)">
        <span>更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="swiper-container container2" :style="{ width: coupon.length ? '786px' : '1120px' }">
      <div class="item_wrap swiper-wrapper">
        <!-- .slice(0, 4) -->
        <div class="item swiper-slide" v-for="(item, i) in seckillData.goods" :key="i" @click="handleDetail(item.id)">
          <!-- <div class="left-goods-box"></div> -->
          <div class="img">
            <img :src="item.logo" alt="" />
          </div>
          <p class="title shenglue_1">{{ item.title }}</p>
          <p class="shenglue_1">{{ item.sku }}</p>
          <p class="shenglue_1 mb_20">{{ item.scqy }}</p>
          <div class="price">
            <p>￥{{ item.price }}</p>
            <div><span>抢</span></div>
          </div>
        </div>
      </div>

      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { handleJump } from "@/utils";
export default {
  // props: {
  //   seckill: {
  //     type: Object,
  //     default: () => {},
  //   },
  //   coupon: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  props: ["seckill", "coupon"],
  watch: {
    seckill: {
      handler(newVal) {
        // console.log(oldVal);
        this.seckillData = newVal;
        this.time_str = this.seckillData.countdown_time;
        this.countdown_time = this.InitTime(this.time_str);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      handleJump,
      seckillData: {},
      time_str: "",
      countdown_time: "",
      djs: "",
    };
  },
  // mounted() {
  //   new Swiper(".container2", {
  //     loop: true,
  //     slidesPerView: this.coupon?.list.length > 0 ? 4 : 6, // 一组三个
  //     spaceBetween: 10, // 间隔
  //     autoplay: {
  //       delay: 5000, // 每次轮播间隔的ms数，默认3000ms
  //       stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
  //     },
  //   });
  // },
  created() {},
  mounted() {
    setInterval(() => {
      // for (var key in this.list) {
      var rightTime = Math.floor((Number(this.time_str) * 1000 - Date.now()) / 1000);
      if (rightTime > 0) {
        var dd = Math.floor(rightTime / 60 / 60 / 24);
        var hh = Math.floor((rightTime / 60 / 60) % 24);
        var mm = Math.floor((rightTime / 60) % 60);
        var ss = Math.floor(rightTime % 60);
      }
      this.djs = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      // }
    }, 1000);
  },
  methods: {
    InitTime(endtime) {
      var dd,
        hh,
        mm,
        ss = null;
      var time = Math.floor((endtime * 1000 - Date.now()) / 1000);
      if (time <= 0) {
        return "结束";
      } else {
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time % 60);
        var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        return str;
      }
    },
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      // let firstTab = this.$store.state.pageName;
      // console.log("面包屑---", firstTab);
      console.log("首页---", item);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      // if (item.url.url == "/index/allGoods") {
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/allGoods",
        query: { area_id: item.id },
      });
      // }
      // handleJump(item.url);
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  width: 786px;
  // width: 1140px;
  // width:calc(100% - 20px);
  padding: 0px 5px;
  height: 301px;
  // background-color: #feeb47;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
}
.swiper-wrapper {
  width: 188px;
  height: 301px;
}

.inner {
  width: 816px;
  // width: 1200px;
  // width:100%;
  height: 389px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
      span {
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-style: italic;
        color: #f14948;
      }
      .time-down {
        margin-left: 10px;
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-style: italic;
        color: #f14948;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        line-height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .item_wrap {
    .item {
      width: 188px;
      height: 301px;
      background: #ffffff;
      border: 1px solid #f63535;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      margin-right: 10px;
      .img {
        width: 150px;
        height: 150px;
        background: #eeeeee;
        border-radius: 4px;
        margin-top: 10px;
      }
      p {
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 1.8;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 2;
        margin-bottom: 4px;
      }
      .price {
        // width: 188px;
        width: 100%;
        height: 36px;
        background: linear-gradient(90deg, #ff6d17 0%, #ef3517 80%);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        p {
          font-size: 16px;
          line-height: 36px;
          font-weight: bold;
          color: #fefefe;
        }
        div {
          width: 56px;
          height: 36px;
          background: #feeb47;
          font-size: 16px;
          font-weight: bold;
          color: #6e1f11;
          position: relative;
          span {
            position: absolute;
            right: 10px;
            top: 8px;
          }
          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            width: 0;
            height: 0;
            border: 18px solid #ef3517;
            border-top-color: transparent;
            border-right-color: transparent;
          }
        }
      }
    }
  }
}
</style>
