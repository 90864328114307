<template>
  <div class="info_wrap">
    <div class="avatar_wrap">
      <div class="flex_row_aic">
        <div class="avatar">
          <img :src="url" alt="" />
        </div>
        <!-- <p>欢迎来到{{ setting.title }}</p> -->
      </div>
      <div class="btn_wrap" v-if="!isLogin">
        <div class="btn" @click="$router.push('/login')">登录</div>
        <div class="btn zhu" @click="$router.push('/register')">注册</div>
      </div>
      <div class="btn_wrap" v-else>
        <div class="btn zhu c_p" @click="$router.push('/index/cart')">购物车</div>
      </div>
    </div>
    <div class="grid_wrap">
      <div class="item_wrap">
        <div class="item" v-for="item in menuList" :key="item.name" @click="handleClick(item)">
          <div class="img_icon">
            <img :src="item.icon" alt="" />
          </div>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="announce_wrap">
      <div class="title_box">
        <p>最新公告</p>
        <div @click="$router.push('/index/news')">
          <span class="more">更多</span>
          <div class="mo">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="article">
        <div class="cont f12" :class="{ 'animate-up': animateUp }" v-for="item in list" :key="item.title" @click="handleMore(item)">
          <div class="left">
            <div clang="dian">·</div>
            <div class="sp">{{ item.title }}</div>
          </div>
          <div class="right">{{ item.time_str }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogin, backToLogin } from "@/utils";
import icon1 from "@/assets/img/index/icon1.png";
import icon2 from "@/assets/img/home/coupon.png";
import icon3 from "@/assets/img/index/icon3.png";
import icon4 from "@/assets/img/index/icon4.png";
import icon5 from "@/assets/img/index/icon5.png";
import icon6 from "@/assets/img/index/icon6.png";
import header_img from "@/assets/img/home/header_img.png";
export default {
  props: ["infoData", "setting"],
  data() {
    return {
      list: [],
      isLogin: isLogin(),
      animateUp: false,
      timer: null,
      url: header_img,
      menuList: [
        { icon: icon1, name: "个人中心", path: "/index/subRouter/homepage" },
        // { icon: icon2, name: "我的求购", path: "/index/subRouter/purchase" },
        { icon: icon2, name: "领券中心", path: "/index/getcoupon" },
        { icon: icon3, name: "我的订单", path: "/index/subRouter/order" },
        { icon: icon4, name: "我的收藏", path: "/index/subRouter/collect" },
        { icon: icon5, name: "我的资产", path: "/index/subRouter/balance" },
        { icon: icon6, name: "常购清单", path: "/index/subRouter/quick" },
      ],
    };
  },
  created() {
    // console.log("666---------------------------------", this.$store.state.homeComData.setting);
  },
  watch: {
    infoData: {
      handler(newVal) {
        // console.log(oldVal);
        // this.showFlag = newVal;
        this.list = newVal;
        if (this.list && this.list.length > 4) {
          this.timer = setInterval(this.scrollAnimate, 2000);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async handleClick(item) {
      await backToLogin();
      if (item.path) {
        if (item.name == "领券中心") {
          let menuList = this.$store.state.menuData;
          let ind = menuList.findIndex((item) => item.title == "领券中心");
          this.$store.commit("CHANGE_PAGENAME", ["领券中心"]);
          this.$store.commit("CHANGE_TAB", ind);
          this.$router.push(item.path);
        } else {
          this.$router.push(item.path);
        }
      }
    },
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.list.push(this.list[0]);
        this.list.shift();
        this.animateUp = false;
      }, 500);
    },
    handleMore(item) {
      console.log("消息项----", item);
      this.$router.push({
        path: "/index/news",
        query: { name: item.title, id: item.id, type: "news" },
      });

      // this.$router.push({
      //   name: "News",
      //   params: { id: item.id, type: "news" },
      // });
    },
  },
  mounted() {
    // this.$api("home.getMessage").then((res) => {
    //   this.list = res.data;
    //   if (this.list.length > 4) {
    //     this.timer = setInterval(this.scrollAnimate, 2000);
    //   }
    // });
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.info_wrap {
  width: 190px;
  height: 480px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  .avatar_wrap {
    padding-top: 10px;
    box-sizing: border-box;
    height: 106px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      // margin-right: 8px;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .welcome-title {
      width: 100px;
      font-size: 12px;
      color: #999;
    }
    p {
      font-size: 12px;
      color: #999;
    }
    .btn_wrap {
      display: flex;
      margin-top: 8px;
      width: 100%;
      align-items: center;
      justify-content: center;
      .btn {
        cursor: pointer;
        box-sizing: border-box;
        width: 80px;
        height: 28px;
        background: @themeColor;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
      .zhu {
        background: #fff;
        border: 1px solid @themeColor;
        color: @themeColor;
        // margin-left: 10px;
      }
    }
  }
  .grid_wrap {
    // margin-top: 15px;
    .item_wrap {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      border-bottom: 1px solid #f2f2f2;
      .item {
        width: 50%;
        height: 74px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 3;
        cursor: pointer;
        box-sizing: border-box;
        border-top: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        &:nth-child(2n + 1) {
          border-left: none;
        }
        .img_icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .announce_wrap {
    margin-top: 10px;
    box-sizing: border-box;
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 7px;
      p {
        font-weight: bold;
        color: #333333;
      }
      .more {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-right: 6px;
        cursor: pointer;
      }
      .mo {
        display: inline-block;
        font-size: 12px;
      }
    }
    .article {
      line-height: 1.8;
      height: 130px;
      overflow: hidden;
      .animate-up {
        transition: all 0.5s ease-in-out;
        transform: translateY(-23px);
      }
      .cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          color: @themeColor;
        }
        .left {
          width: 50%;
          display: flex;
          align-items: center;
          .sp {
            // background-color: brown;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -o-line-clamp: 1;
            overflow: hidden;
          }
        }
        .right {
          width: 50%;
          text-align: right;
        }
      }
    }
  }
}
</style>
