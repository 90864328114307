<template>
  <div class="backdrop">
    <!-- <div class="top-img">
      <img :src="data.icon_logo" alt="" />
    </div> -->
    <!-- <div class="more" @click="handleJump(data.floor[0].jump)">更多 <i class="el-icon-arrow-right"></i></div> -->
    <div class="floor-item" :id="item.id" v-for="(item, index) in data" :key="index">
      <div class="title-box">
        <!-- <div>{{ item.title }}</div> -->
        <div class="left-title-box">
          <img v-if="item.icon_logo" :src="item.icon_logo" style="width: 120px; height: 29px" alt="" />
          <!-- <span>{{ item.subtitle }}</span> -->
          <!-- <div class="subtitle-style">{{ item.subtitle }}</div> -->
          <div v-if="!item.icon_logo" class="main-title" style="font-size: 22px; color: #12288a; font-weight: bold; height: 29px; display: flex; align-items: flex-end; margin-right: 6px">
            {{ item.title }}
          </div>
          <div v-if="!item.icon_logo" class="subtitle-style" style="font-size: 16px; color: #000000; height: 29px; display: flex; align-items: flex-end">{{ item.subtitle }}</div>
        </div>
        <div class="more" @click="handleMore(item)">更多 <i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="img-box">
        <img :src="item.banner[0].logo" alt="" />
      </div>

      <div class="item_wrap">
        <div class="item" v-for="k in item.goods.slice(0, 5)" :key="k.id" @click="handleDetail(k.id)">
          <div class="img">
            <img :src="k.logo" alt="" />
          </div>
          <p class="pr">￥{{ k.price }}</p>
          <tagcom :tags="k.tags"></tagcom>
          <p class="til shenglue_1">{{ k.title }}</p>
          <p class="shenglue_1 f12">{{ k.sku }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tagcom from "@/components/tag.vue";
import { handleJump } from "@/utils";
export default {
  components: {
    tagcom,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      console.log("更多---", item);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/allGoods",
        query: { floor_id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.backdrop {
  width: 1200px;
  // height: 730px;
  // background: #ffffff;
  border-radius: 4px;
  // margin-top: 10px;
  // padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  position: relative;
  .floor-item {
    width: 1160px;
    display: flex;
    flex-direction: column;
    // height: 200px;
    padding: 20px;
    height: auto;
    margin-top: 10px;
    background-color: #fff;
    // background-color: cadetblue;
    // .goods-box {
    // width: 100%;
    // height: auto;
    // background-color: #f1622f;
    // .top-img {
    //   width: 100%;
    //   height: 100px;
    //   background-color: #f1622f;
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    // }
    .title-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
      padding-top: 0;
      .left-title-box {
        width: 70%;
        height: 100%;
        // background-color: #ffd4c4;
        display: flex;
        align-items: center;
        .subtitle-style {
          height: 100%;
          height: 29px;
          // text-align: end;
          font-size: 14px;
          color: #515a6e;
          display: flex;
          align-items: flex-end;
          // background-color: aquamarine;
        }
      }
      .more {
        // position: absolute;
        // top: 20px;
        // right: 20px;
        color: #333;
        cursor: pointer;
        font-size: 12px;
      }
    }
    .img-box {
      width: 100%;
      height: 150px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    .item_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      // margin-top: 110px;
      .item {
        width: 221px;
        height: 275px;
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid #f1622f;
        background: linear-gradient(0deg, #ffd4c4 0%, #ffffff 40%);
        box-shadow: 0px 0px 8px 0px rgba(241, 98, 47, 0.3);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:nth-child(-n + 5) {
          margin-bottom: 14px;
        }
        &:hover {
          box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
        }
        .img {
          margin-top: 10px;
          width: 150px;
          height: 150px;
          background-color: #eee;
        }
        p {
          color: #333;
        }
        .pr {
          color: @priceRed;
          font-size: 16px;
          font-weight: bold;
          margin: 8px 0;
          margin-bottom: 0;
        }
        .til {
          font-weight: bold;
          color: #333333;
          margin-bottom: 5px;
        }
      }
    }
    // }
  }
}
</style>
