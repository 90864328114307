<template>
  <div class="inner">
    <div class="tile_wrap">
      <div class="left">
        <div class="img">
          <img src="@/assets/img/index/pin.png" alt="" />
        </div>
        <span>活动</span>
      </div>
      <div class="right c_p" @click="jumpMore">
        <span>更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="cont">
      <!-- <div class="cou_item" v-for="item in coupon" :key="item.id" @click="handleJumpFn(item)">
        <div>{{ item.title }}</div>
        <img :src="item.logo" alt="" />
      </div> -->
      <el-carousel class="banner-box" height="301px" :autoplay="true" :interval="7000">
        <el-carousel-item class="img" v-for="item in coupon" :key="item.id" @click.native="handleJumpFn(item)">
          <img :src="item.logo" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
import { mapGetters } from "vuex";
export default {
  props: {
    coupon: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  computed: {
    ...mapGetters(["menuData"]),
  },
  methods: {
    jumpMore() {
      // console.log("菜单---", this.menuData);
      let index = this.menuData.findIndex((item) => item.pcJump.value === "/index/activity");
      // console.log("活动中心的下标---", index);
      this.$store.commit("CHANGE_TAB", index);
      this.$router.push({
        path: "/index/activity",
      });
    },
    handleJumpFn(data) {
      console.log("跳转----", data.pcJump);
      handleJump(data.pcJump);
    },
  },
};
</script>
<style lang="less" scoped>
.inner {
  width: 374px;
  height: 389px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
      span {
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-style: italic;
        color: #f14948;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        line-height: 100%;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .cont {
    padding: 0 20px;
    border-radius: 4px;
    .cou_item {
      width: 329px;
      height: 116px;
      position: relative;
      margin-top: 20px;
      img {
        position: absolute;
      }
      .box {
        width: 329px;
        height: 116px;
        position: absolute;
        display: flex;
        align-items: center;
        .pr {
          width: 130px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          span {
            font-size: 46px;
          }
        }
        .flex_1 {
          text-align: center;
          color: #ffffff;
          .p1 {
            font-size: 20px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
</style>
