<template>
  <div class="goods_cate">
    <div class="content" @mouseleave="show = false">
      <div class="cate_wraps" v-for="(item, i) in cateList" :key="i" @mouseenter="mouseenterOut(i, item)" :class="[i === currentOutId ? 'active' : '']">
        <div class="img">
          <img :src="item.logo" alt="" />
        </div>
        <span class="shenglue_1 flex_1" style="margin-right: 10px">{{ item.title }}</span>
      </div>
      <div class="detail" v-show="show" @mouseenter="mouseenterInner" @mouseleave="show = false">
        <div class="inner" v-for="item in currentItem.sub" :key="item.title">
          <p class="til">{{ item.title }}</p>
          <div class="item_item">
            <div class="flex_row" @mouseenter="mouseenterItem(sub.id)" v-for="sub in item.sub" :key="sub.id" :class="[sub.id === currentId ? 'active' : '']" @click="goto(sub)">
              <p class="p1">{{ sub.title }}</p>
              <p class="p1">|</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { handleJump } from "@/utils";
import { LsetItem } from "@/utils/storage";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cateList: [],
      currentItem: {},
      show: false,
      currentOutId: "",
      currentId: "",
      // showCate: true,
    };
  },
  computed: {
    ...mapGetters(["goodsListCate"]),
  },
  watch: {
    show(val) {
      if (!val) {
        this.currentOutId = "";
        this.currentId = "";
      }
    },
    goodsListCate(newVal) {
      this.show = newVal;
      console.log("show------", this.show);
    },
  },
  created() {
    // console.log("初始化----分类显示的公共状态----", this.$store.state.goodsListCate);
    this.show = this.$store.state.goodsListCate;
    this.getCateData();
  },
  methods: {
    getCateData() {
      this.$api("home.getCate").then((res) => {
        this.cateList = res.data;
      });
    },
    goto(item) {
      // console.log("选中的分类-----", item);
      this.$store.commit("setGoodsListCate", false);

      // console.log("点击修改----分类显示的公共状态----", this.$store.state.goodsListCate);
      // let menuList = this.$store.state.menuData;
      // let ind = menuList.findIndex((item) => item.pcJump.value == "/index/allGoods");
      // if (ind >= 0) {
      //   this.$store.commit("CHANGE_TAB", ind);
      //   LsetItem("active_tab", ind);
      // } else {
      //   this.$store.commit("CHANGE_TAB", -1);
      //   LsetItem("active_tab", -1);
      // }
      this.$emit("change", false);
      this.$router.push({
        path: "/index/allGoods",
        query: { cate_id: item.id },
        // name: "AllGoods",
        // params: { cate_id: item.id },
      });
      // handleJump(item.url);
      // console.log("子组件调用父组件的方法----false");
      // this.show = true;

      this.$emit("change", false);
      LsetItem("showCate", false);
      // this.$store.commit("setGoodsListCate", false);
      // this.showCate = false;
      this.show = false;
    },
    mouseenterOut(i, item) {
      this.currentOutId = i;
      this.currentItem = item;
      this.show = true;
    },
    mouseenterInner() {
      this.show = true;
      // this.$store.commit("setGoodsListCate", false);
    },
    mouseenterItem(i) {
      this.currentId = i;
    },
    // leaveItem() {
    //   this.show = false;
    // },
  },
};
</script>

<style lang="less" scoped>
.goods_cate {
  z-index: 999;
  width: 200px;
  height: 480px;
  background-color: #fff;
  position: relative;
  .content {
    overflow: scroll;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding-top: 40rpx;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .cate_wraps {
      padding-left: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 40px;
      flex-shrink: 0;
      &:hover {
        background-color: @themeColor;
        color: #fff;
      }
      &.active {
        background-color: @themeColor;
        color: #fff;
      }
      .img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      span {
      }
    }
  }
  .detail {
    position: absolute;
    left: 200px;
    width: 540px;
    height: 100%;
    background-color: #fff;
    // background-color: cadetblue;
    border: 1px solid @themeColor;
    overflow-y: auto;
    .inner {
      padding: 20px;
      .til {
        font-weight: bold;
        color: #000;
        margin-bottom: 8px;
      }
      .item_item {
        display: flex;
        cursor: pointer;
        flex-wrap: wrap;
        .p1 {
          font-size: 14px;
          font-weight: 400;
          color: #666;
          margin-right: 15px;
          line-height: 2;
        }
        .active .p1 {
          color: @themeColor;
        }
      }
    }
  }
}
</style>
