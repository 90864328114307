<template>
  <div>
    <div class="popup" v-if="dialogVisible && obj.img" @click="dialogVisible = false">
      <div class="po">
        <el-button icon="el-icon-close" circle @click="dialogVisible = false"></el-button>
        <el-image :src="obj && obj.img" @load="loadImage" @click="handleJump"> </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { loadImage } from "@/utils";
export default {
  data() {
    return { obj: {}, dialogVisible: true, loadImage };
  },
  created() {},
  mounted() {
    // this.$api("home.getPopup").then((res) => {
    //   this.obj = res.data;
    //   let time = Date.parse(new Date(res.data.jssj).toString());
    //   let timeNow = new Date().getTime();
    //   if (timeNow > time) {
    //      this.dialogVisible = false;
    //   }
    // });
  },
  destroyed() {
    this.dialogVisible = false;
  },
  methods: {
    handleJump() {
      if (this.obj.url) {
        window.open(this.obj.url, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .po {
    position: relative;
    .el-button {
      position: absolute;
      right: -50px;
      top: -50px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
}
</style>
