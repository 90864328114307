<template>
  <div class="flex_row flex-wrap-tag" style="height: 26px; position: relative">
    <div class="flex_row" v-for="item in tags" :key="item.title" @mouseenter="mouseenter(item)" @mouseleave="showtag = false">
      <!-- <el-tooltip :content="item.hdstr" placement="bottom" effect="light" :popper-class="'popperclass'"> -->
      <div :style="`backgroundColor: #${item.color}; border: 1px solid #${item.color};`" class="tag mr_10">
        {{ item.title }}
      </div>

      <div v-if="showtag" :class="[poi == 'top' ? 'top' : 'bottom']" :style="`color: #${item.color}; border: 1px solid #${item.color};`">{{ item.hdstr }}</div>
      <!-- </el-tooltip> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
    // tags: {
    //   type: Array,
    //   default: () => [],
    // },
    poi: {
      type: String,
      default: "top",
    },
  },
  data() {
    return {
      showtag: false,
    };
  },
  methods: {
    mouseenter() {
      // if (item.hdstr) this.showtag = true;
    },
  },
};
</script>

<style lang="less" scoped>
.flex-wrap-tag {
  flex-wrap: wrap;
}
.tag {
  margin-top: 8px;
  padding: 0 5px;
  height: 16px;
  line-height: 16px;
  border: 1px solid #2b9dfc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  // color: #2b9dfc;
  color: #fff;
  border-radius: 4px;
}
.popperclass {
  font-size: 12px;
  color: red;
  background-color: #fff;
  width: 220px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 9px;
}

.top {
  z-index: 9999;
  font-size: 12px;
  position: absolute;
  background-color: #fff;
  width: 220px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 9px;
  box-sizing: border-box;
  top: 30px;
  left: -10px;
}
.bottom {
  z-index: 9999;
  font-size: 12px;
  position: absolute;
  background-color: #fff;
  width: 220px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 9px;
  box-sizing: border-box;
  bottom: 22px;
  left: -30px;
}
</style>
